import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import { appUrls } from "../../urls";
import { useFormikContext } from "formik";
import {
  FieldError,
  FieldInput,
  FieldLabel,
  Form,
  FormControl,
} from "../../components/Input";
import Button from "../../components/Button";
import Entry from "../../layouts/Entry";
import styles from "./ValidateLogin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/Icon";
import { validateLogin, regenerateCode } from "../../redux";
import { I18nContext } from "../../i18n/I18nContext";

export const ErrorListener = ({ onError }) => {
  const formik = useFormikContext();

  useEffect(() => {
    onError(formik.errors);
    // eslint-disable-next-line
  }, [formik.errors]);

  return null;
};

const ValidateLogin = () => {
  const { t, language, changeLanguage } = useContext(I18nContext);
  const dispatch = useDispatch();
  const userLanguage = useSelector((state) => state.auth.info.languageCode);
  const [hasError, setHasError] = useState();

  const tokenString = useSelector((state) => state.auth.info?.token);
  const hasApp = useSelector((state) => state.auth.info?.haveMobileApp);

  useEffect(() => {
    if (!!userLanguage && language !== userLanguage) {
      changeLanguage(userLanguage);
    }
  }, [userLanguage]);

  const initialValues = {
    code: "",
    token: tokenString,
  };

  const validationSchema = Yup.object({
    code: Yup.string()
      .required(t("please enter validation code"))
      .min(6, t("validation code must have 6 characters"))
      .max(6, t("validation code must have 6 characters")),
  });

  const handleSubmit = (values) => {
    dispatch(validateLogin(t, values, hasApp));
  };

  const sendCodeToEmail = () => {
    dispatch(regenerateCode(t));
  };

  return (
    <Entry>
      <div className={styles.container}>
        {!hasError ? (
          <span className={styles.icon_circle}>
            <Icon className={styles.icon} name="padlock" />
          </span>
        ) : (
          <span className={styles.icon_circleRed}>
            <Icon className={styles.icon} name="warning" />
          </span>
        )}
        <span className={styles.header}>{t("validate login")}</span>
        <span className={styles.txt_container}>
          {hasApp
            ? t("confirm login attempt in mobile app and enter validation code")
            : t(
                "check your inbox and enter validation code provided in email message"
              )}
          .
        </span>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <FormControl>
            <FieldLabel>
              {t("validation code")}
              <span className={styles.red}>*</span>
            </FieldLabel>
            <FieldInput name="code" />
            <FieldError name="code" />
          </FormControl>
          <ErrorListener onError={(errors) => setHasError(!!errors.code)} />
          <div className={styles.row}>
            <div
              className={styles.resend_code_button}
              onClick={sendCodeToEmail}
            >
              {hasApp ? t("send code to email") : t("resend code")}
            </div>
          </div>
          <Button type="submit" variant="orange" size="l">
            {t("validate")}
          </Button>
        </Form>
        <Link className={styles.link_return} to={appUrls.LOGIN}>
          {t("back to login")}
        </Link>
      </div>
    </Entry>
  );
};

export default ValidateLogin;
